<template>
  <b-container>
    <b-link class="brand-logo">
      <img
        src="@/assets/images/logo/ford-logo.png"
        alt="logo"
      >
    </b-link>
    <h2 class="text-center mb-2">
      Ankieta poszkoleniowa
    </h2>
    <b-form @submit.prevent="submit">
      <validation-observer
        ref="pollForm"
      >
        <b-card>
          Uprzejmie prosimy o wypełnienie ankiety, zbierającej istotne dane i
          informacje dotyczące szkolenia, w jakim właśnie wziąłeś/wzięłaśięłaś
          udział. Przekazane przez Ciebie informacje stanowić będą dane źródłowe
          niezbędne dla oceny efektywności szkoleń prowadzonych w ramach
          projektu Akademia Forda. Prosimy o wypełnienie wszystkich rubryk.
          <hr>
          <h3>Ocena programu szkolenia i metod prowadzenia szkolenia</h3>
          Prosimy o zaznaczenie w jakim stopniu zgadzasz się z poniższymi
          stwierdzeniami:

          <b-table-simple
            class="poll-table d-none d-lg-block"
            bordered
            striped
            responsive
            :small="$store.getters['app/currentBreakPoint'] === 'xs'"
          >
            <b-thead>
              <b-tr>
                <b-th>
                  <span class="text-primary">Skala</span>
                </b-th>
                <b-th> Zgadzam się </b-th>
                <b-th> Częściowo się zgadzam </b-th>
                <b-th> Nie zgadzam się </b-th>
              </b-tr>
            </b-thead>
            <b-tbody>
              <b-tr>
                <b-td>Dzięki udziałowi w szkoleniu zdobyłem nową wiedzę/utrwaliłem
                  dotychczasową oraz zdobyłem nowe umiejętności potrzebne na
                  moim stanowisku pracy</b-td>
                <b-td>
                  <validation-provider
                    #default="{ errors }"
                    rules="required"
                  ><b-form-radio
                    v-model="question1"
                    required
                    :state="errors.length > 0 ? false : null"
                    value="AGREE"
                    name="question1"
                  /></validation-provider>
                </b-td>
                <b-td>
                  <validation-provider
                    #default="{ errors }"
                    rules="required"
                  ><b-form-radio
                    v-model="question1"
                    required
                    :state="errors.length > 0 ? false : null"
                    value="PARTIALLY_AGREE"
                    name="question1"
                  /></validation-provider>
                </b-td>
                <b-td>
                  <validation-provider
                    #default="{ errors }"
                    rules="required"
                  ><b-form-radio
                    v-model="question1"
                    required
                    :state="errors.length > 0 ? false : null"
                    value="DO_NOT_AGREE"
                    name="question1"
                  /></validation-provider>
                </b-td>
              </b-tr>
              <b-tr>
                <b-td>Metody szkolenia, rytm pracy i środki dydaktyczne pomogły
                  osiągnąć cele szkolenia</b-td>
                <b-td>
                  <validation-provider
                    #default="{ errors }"
                    rules="required"
                  ><b-form-radio
                    v-model="question2"
                    required
                    :state="errors.length > 0 ? false : null"
                    value="AGREE"
                    name="question2"
                  /></validation-provider>
                </b-td>
                <b-td>
                  <validation-provider
                    #default="{ errors }"
                    rules="required"
                  ><b-form-radio
                    v-model="question2"
                    required
                    :state="errors.length > 0 ? false : null"
                    value="PARTIALLY_AGREE"
                    name="question2"
                  /></validation-provider>
                </b-td>
                <b-td>
                  <validation-provider
                    #default="{ errors }"
                    rules="required"
                  ><b-form-radio
                    v-model="question2"
                    required
                    :state="errors.length > 0 ? false : null"
                    value="DO_NOT_AGREE"
                    name="question2"
                  /></validation-provider>
                </b-td>
              </b-tr>
            </b-tbody>
          </b-table-simple>
          <div class="poll-mobile d-lg-none">
            <b-form-group class="mt-1">
              <strong>
                Dzięki udziałowi w szkoleniu zdobyłem nową wiedzę/utrwaliłem
                dotychczasową oraz zdobyłem nowe umiejętności potrzebne na
                moim stanowisku pracy
              </strong>
              <validation-provider
                #default="{ errors }"
                rules="required"
              ><b-form-radio
                v-model="question1"
                required
                :state="errors.length > 0 ? false : null"
                value="AGREE"
                name="question1m"
              >
                Zgadzam się
              </b-form-radio></validation-provider>
              <validation-provider
                #default="{ errors }"
                rules="required"
              ><b-form-radio
                v-model="question1"
                required
                :state="errors.length > 0 ? false : null"
                value="PARTIALLY_AGREE"
                name="question1m"
              >
                Częściowo się zgadzam
              </b-form-radio></validation-provider>
              <validation-provider
                #default="{ errors }"
                rules="required"
              ><b-form-radio
                v-model="question1"
                required
                :state="errors.length > 0 ? false : null"
                value="DO_NOT_AGREE"
                name="question1m"
              >
                Nie zgadzam się
              </b-form-radio></validation-provider>
            </b-form-group>
            <b-form-group class="mt-1">
              <strong>
                Metody szkolenia, rytm pracy i środki dydaktyczne pomogły
                osiągnąć cele szkolenia
              </strong>
              <validation-provider
                #default="{ errors }"
                rules="required"
              ><b-form-radio
                v-model="question2"
                required
                :state="errors.length > 0 ? false : null"
                value="AGREE"
                name="question2m"
              >
                Zgadzam się
              </b-form-radio></validation-provider>
              <validation-provider
                #default="{ errors }"
                rules="required"
              ><b-form-radio
                v-model="question2"
                required
                :state="errors.length > 0 ? false : null"
                value="PARTIALLY_AGREE"
                name="question2m"
              >
                Częściowo się zgadzam
              </b-form-radio></validation-provider>
              <validation-provider
                #default="{ errors }"
                rules="required"
              ><b-form-radio
                v-model="question2"
                required
                :state="errors.length > 0 ? false : null"
                value="DO_NOT_AGREE"
                name="question2m"
              >
                Nie zgadzam się
              </b-form-radio></validation-provider>
            </b-form-group>
            <hr>
          </div>

          <h3>Ocena pracy trenera</h3>
          Prosimy o zaznaczenie w jakim stopniu zgadzasz się z poniższymi
          stwierdzeniami:

          <b-table-simple
            class="poll-table d-none d-lg-block"
            bordered
            striped
            responsive
            :small="$store.getters['app/currentBreakPoint'] === 'xs'"
          >
            <b-thead>
              <b-tr>
                <b-th>
                  <span class="text-primary">Skala</span>
                </b-th>
                <b-th> Zgadzam się </b-th>
                <b-th> Częściowo się zgadzam </b-th>
                <b-th> Nie zgadzam się </b-th>
              </b-tr>
            </b-thead>
            <b-tbody>
              <b-tr>
                <b-td>Doświadczenie trenera było adekwatne do tematu
                  szkolenia</b-td>
                <b-td>
                  <validation-provider
                    #default="{ errors }"
                    rules="required"
                  ><b-form-radio
                    v-model="question3"
                    required
                    :state="errors.length > 0 ? false : null"
                    value="AGREE"
                    name="question3"
                  /></validation-provider>
                </b-td>
                <b-td>
                  <validation-provider
                    #default="{ errors }"
                    rules="required"
                  ><b-form-radio
                    v-model="question3"
                    required
                    :state="errors.length > 0 ? false : null"
                    value="PARTIALLY_AGREE"
                    name="question3"
                  /></validation-provider>
                </b-td>
                <b-td>
                  <validation-provider
                    #default="{ errors }"
                    rules="required"
                  ><b-form-radio
                    v-model="question3"
                    required
                    :state="errors.length > 0 ? false : null"
                    value="DO_NOT_AGREE"
                    name="question3"
                  /></validation-provider>
                </b-td>
              </b-tr>
              <b-tr>
                <b-td>Postawa trenera była przyjazna, profesjonalna i
                  zaangażowana</b-td>
                <b-td>
                  <validation-provider
                    #default="{ errors }"
                    rules="required"
                  ><b-form-radio
                    v-model="question4"
                    required
                    :state="errors.length > 0 ? false : null"
                    value="AGREE"
                    name="question4"
                  /></validation-provider>
                </b-td>
                <b-td>
                  <validation-provider
                    #default="{ errors }"
                    rules="required"
                  ><b-form-radio
                    v-model="question4"
                    required
                    :state="errors.length > 0 ? false : null"
                    value="PARTIALLY_AGREE"
                    name="question4"
                  /></validation-provider>
                </b-td>
                <b-td>
                  <validation-provider
                    #default="{ errors }"
                    rules="required"
                  ><b-form-radio
                    v-model="question4"
                    required
                    :state="errors.length > 0 ? false : null"
                    value="DO_NOT_AGREE"
                    name="question4"
                  /></validation-provider>
                </b-td>
              </b-tr>
              <b-tr>
                <b-td>Sposób przekazywania wiedzy, prowadzenia dyskusji i
                  odpowiadania na pytania sprzyjał atmosferze i osiągnięciu celu
                  szkolenia</b-td>
                <b-td>
                  <validation-provider
                    #default="{ errors }"
                    rules="required"
                  ><b-form-radio
                    v-model="question5"
                    required
                    :state="errors.length > 0 ? false : null"
                    value="AGREE"
                    name="question5"
                  /></validation-provider>
                </b-td>
                <b-td>
                  <validation-provider
                    #default="{ errors }"
                    rules="required"
                  ><b-form-radio
                    v-model="question5"
                    required
                    :state="errors.length > 0 ? false : null"
                    value="PARTIALLY_AGREE"
                    name="question5"
                  /></validation-provider>
                </b-td>
                <b-td>
                  <validation-provider
                    #default="{ errors }"
                    rules="required"
                  ><b-form-radio
                    v-model="question5"
                    required
                    :state="errors.length > 0 ? false : null"
                    value="DO_NOT_AGREE"
                    name="question5"
                  /></validation-provider>
                </b-td>
              </b-tr>
            </b-tbody>
          </b-table-simple>
          <div class="poll-mobile d-lg-none">
            <b-form-group class="mt-1">
              <strong>
                Doświadczenie trenera było adekwatne do tematu
                szkolenia
              </strong>
              <validation-provider
                #default="{ errors }"
                rules="required"
              ><b-form-radio
                v-model="question3"
                required
                :state="errors.length > 0 ? false : null"
                value="AGREE"
                name="question3m"
              >
                Zgadzam się
              </b-form-radio></validation-provider>
              <validation-provider
                #default="{ errors }"
                rules="required"
              ><b-form-radio
                v-model="question3"
                required
                :state="errors.length > 0 ? false : null"
                value="PARTIALLY_AGREE"
                name="question3m"
              >
                Częściowo się zgadzam
              </b-form-radio></validation-provider>
              <validation-provider
                #default="{ errors }"
                rules="required"
              ><b-form-radio
                v-model="question3"
                required
                :state="errors.length > 0 ? false : null"
                value="DO_NOT_AGREE"
                name="question3m"
              >
                Nie zgadzam się
              </b-form-radio></validation-provider>
            </b-form-group>
            <b-form-group class="mt-1">
              <strong>
                Postawa trenera była przyjazna, profesjonalna i
                zaangażowana
              </strong>
              <validation-provider
                #default="{ errors }"
                rules="required"
              ><b-form-radio
                v-model="question4"
                required
                :state="errors.length > 0 ? false : null"
                value="AGREE"
                name="question4m"
              >
                Zgadzam się
              </b-form-radio></validation-provider>
              <validation-provider
                #default="{ errors }"
                rules="required"
              ><b-form-radio
                v-model="question4"
                required
                :state="errors.length > 0 ? false : null"
                value="PARTIALLY_AGREE"
                name="question4m"
              >
                Częściowo się zgadzam
              </b-form-radio></validation-provider>
              <validation-provider
                #default="{ errors }"
                rules="required"
              ><b-form-radio
                v-model="question4"
                required
                :state="errors.length > 0 ? false : null"
                value="DO_NOT_AGREE"
                name="question4m"
              >
                Nie zgadzam się
              </b-form-radio></validation-provider>
            </b-form-group>
            <b-form-group class="mt-1">
              <strong>
                Sposób przekazywania wiedzy, prowadzenia dyskusji i
                odpowiadania na pytania sprzyjał atmosferze i osiągnięciu celu
                szkolenia
              </strong>
              <validation-provider
                #default="{ errors }"
                rules="required"
              ><b-form-radio
                v-model="question5"
                required
                :state="errors.length > 0 ? false : null"
                value="AGREE"
                name="question5m"
              >
                Zgadzam się
              </b-form-radio></validation-provider>
              <validation-provider
                #default="{ errors }"
                rules="required"
              ><b-form-radio
                v-model="question5"
                required
                :state="errors.length > 0 ? false : null"
                value="PARTIALLY_AGREE"
                name="question5m"
              >
                Częściowo się zgadzam
              </b-form-radio></validation-provider>
              <validation-provider
                #default="{ errors }"
                rules="required"
              ><b-form-radio
                v-model="question5"
                required
                :state="errors.length > 0 ? false : null"
                value="DO_NOT_AGREE"
                name="question5m"
              >
                Nie zgadzam się
              </b-form-radio></validation-provider>
            </b-form-group>
            <hr>
          </div>

          <h3>
            W jakim stopniu szkolenie, w którym uczestniczyłeś/aś będzie miało
            wpływ na następujące aspekty?
          </h3>

          <b-table-simple
            class="poll-table d-none d-lg-block"
            bordered
            striped
            responsive
            :small="$store.getters['app/currentBreakPoint'] === 'xs'"
          >
            <b-thead>
              <b-tr>
                <b-th>
                  <span class="text-primary">Skala</span>
                </b-th>
                <b-th> Duży wpływ</b-th>
                <b-th> Umiarkowany wpływ </b-th>
                <b-th> Mały wpływ</b-th>
              </b-tr>
            </b-thead>
            <b-tbody>
              <!-- <b-tr>
                <b-td>Jakość Twojej pracy</b-td>
                <b-td>
                  <validation-provider
                    #default="{ errors }"
                    rules="required"
                  ><b-form-radio
                    v-model="question6"
                    required
                    :state="errors.length > 0 ? false : null"
                    value="BIG_IMPACT"
                    name="question6"
                  /></validation-provider>
                </b-td>
                <b-td>
                  <validation-provider
                    #default="{ errors }"
                    rules="required"
                  ><b-form-radio
                    v-model="question6"
                    required
                    :state="errors.length > 0 ? false : null"
                    value="MODERATE_IMPACT"
                    name="question6"
                  /></validation-provider>
                </b-td>
                <b-td>
                  <validation-provider
                    #default="{ errors }"
                    rules="required"
                  ><b-form-radio
                    v-model="question6"
                    required
                    :state="errors.length > 0 ? false : null"
                    value="SMALL_IMPACT"
                    name="question6"
                  /></validation-provider>
                </b-td>
              </b-tr> -->
              <b-tr>
                <b-td>Poprawę efektywności Twojej pracy</b-td>
                <b-td>
                  <validation-provider
                    #default="{ errors }"
                    rules="required"
                  ><b-form-radio
                    v-model="question7"
                    required
                    :state="errors.length > 0 ? false : null"
                    value="BIG_IMPACT"
                    name="question7"
                  /></validation-provider>
                </b-td>
                <b-td>
                  <validation-provider
                    #default="{ errors }"
                    rules="required"
                  ><b-form-radio
                    v-model="question7"
                    required
                    :state="errors.length > 0 ? false : null"
                    value="MODERATE_IMPACT"
                    name="question7"
                  /></validation-provider>
                </b-td>
                <b-td>
                  <validation-provider
                    #default="{ errors }"
                    rules="required"
                  ><b-form-radio
                    v-model="question7"
                    required
                    :state="errors.length > 0 ? false : null"
                    value="SMALL_IMPACT"
                    name="question7"
                  /></validation-provider>
                </b-td>
              </b-tr>
            </b-tbody>
          </b-table-simple>
          <div class="poll-mobile d-lg-none">
            <!-- <b-form-group class="mt-1">
              <strong>
                Jakość Twojej pracy
              </strong>
              <validation-provider
                #default="{ errors }"
                rules="required"
              ><b-form-radio
                v-model="question6"
                required
                :state="errors.length > 0 ? false : null"
                value="BIG_IMPACT"
                name="question6m"
              >
                Duży wpływ
              </b-form-radio></validation-provider>
              <validation-provider
                #default="{ errors }"
                rules="required"
              ><b-form-radio
                v-model="question6"
                required
                :state="errors.length > 0 ? false : null"
                value="MODERATE_IMPACT"
                name="question6m"
              >
                Umiarkowany wpływ
              </b-form-radio></validation-provider>
              <validation-provider
                #default="{ errors }"
                rules="required"
              ><b-form-radio
                v-model="question6"
                required
                :state="errors.length > 0 ? false : null"
                value="SMALL_IMPACT"
                name="question6m"
              >
                Mały wpływ
              </b-form-radio></validation-provider>
            </b-form-group> -->
            <b-form-group class="mt-1">
              <strong>
                Poprawę efektywności Twojej pracy
              </strong>
              <validation-provider
                #default="{ errors }"
                rules="required"
              ><b-form-radio
                v-model="question7"
                required
                :state="errors.length > 0 ? false : null"
                value="BIG_IMPACT"
                name="question7m"
              >
                Duży wpływ
              </b-form-radio></validation-provider>
              <validation-provider
                #default="{ errors }"
                rules="required"
              ><b-form-radio
                v-model="question7"
                required
                :state="errors.length > 0 ? false : null"
                value="MODERATE_IMPACT"
                name="question7m"
              >
                Umiarkowany wpływ
              </b-form-radio></validation-provider>
              <validation-provider
                #default="{ errors }"
                rules="required"
              ><b-form-radio
                v-model="question7"
                required
                :state="errors.length > 0 ? false : null"
                value="SMALL_IMPACT"
                name="question7m"
              >
                Mały wpływ
              </b-form-radio></validation-provider>
            </b-form-group>
            <hr>
          </div>

          <h3>Referencje</h3>
          <b-form-group class="mt-1">
            <strong>Czy poleciłbyś to szkolenie kolegom / koleżankom?</strong>
            <validation-provider
              #default="{ errors }"
              rules="required"
            ><b-form-radio
              v-model="question8"
              required
              :state="errors.length > 0 ? false : null"
              value="DEFINITELY_YES"
              name="question8"
            >
              Zdecydowanie tak
            </b-form-radio></validation-provider>
            <validation-provider
              #default="{ errors }"
              rules="required"
            ><b-form-radio
              v-model="question8"
              required
              :state="errors.length > 0 ? false : null"
              value="YES"
              name="question8"
            >
              Tak
            </b-form-radio></validation-provider>
            <validation-provider
              #default="{ errors }"
              rules="required"
            ><b-form-radio
              v-model="question8"
              required
              :state="errors.length > 0 ? false : null"
              value="NO"
              name="question8"
            >
              Nie
            </b-form-radio></validation-provider>
            <validation-provider
              #default="{ errors }"
              rules="required"
            ><b-form-radio
              v-model="question8"
              required
              :state="errors.length > 0 ? false : null"
              value="DEFINITELY_NO"
              name="question8"
            >
              Zdecydowanie nie
            </b-form-radio></validation-provider>
          </b-form-group>
          <hr>
          <h3>Twoja opinia o szkoleniu</h3>
          <b-form-textarea
            v-model="comment"
            class="mt-1"
            style="height: 10em"
          />

          <b-button
            class="mt-2"
            block
            type="submit"
            variant="gradient-primary"
            :disabled="loading"
          >
            Wyślij
          </b-button>
        </b-card>
      </validation-observer>
    </b-form>
    <app-footer />
  </b-container>
</template>

<script>
import {
  BContainer,
  BLink,
  BCard,
  BTableSimple,
  BThead,
  BTbody,
  BTr,
  BTh,
  BTd,
  BFormRadio,
  BFormGroup,
  BFormTextarea,
  BButton,
  BForm,
} from 'bootstrap-vue'

import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required } from '@validations'
import AppFooter from '@core/layouts/components/AppFooter.vue'

export default {
  components: {
    BContainer,
    BLink,
    BCard,
    BTableSimple,
    BThead,
    BTbody,
    BTr,
    BTh,
    BTd,
    BFormRadio,
    BFormGroup,
    BFormTextarea,
    BButton,
    BForm,
    ValidationProvider,
    ValidationObserver,
    AppFooter,
  },
  data() {
    return {
      question1: null,
      question2: null,
      question3: null,
      question4: null,
      question5: null,
      // question6: null,
      question7: null,
      question8: null,
      comment: null,
      required,
      loading: false,
    }
  },
  computed: {
    trainingCourse() {
      return `/training_courses/${this.$route.params.id}`
    },
    trainingCourseUuid() {
      return this.$route.params.uuid
    },
  },
  mounted() {
    const localSubmittedPolls = JSON.parse(localStorage.getItem('submitted_polls')) || []
    if (localSubmittedPolls.includes(this.trainingCourseUuid)) {
      this.$router.replace('/poll/already-submitted')
    }
  },
  methods: {
    submit() {
      this.$refs.pollForm.validate().then(valid => {
        if (valid) {
          this.loading = true
          this.$http.post('training_course_polls', {
            trainingCourse: this.trainingCourse,
            trainingCourseUuid: this.trainingCourseUuid,
            question1: this.question1,
            question2: this.question2,
            question3: this.question3,
            question4: this.question4,
            question5: this.question5,
            // question6: this.question6,
            question7: this.question7,
            question8: this.question8,
            comment: this.comment,
          }).then(response => {
            if (response && response.status === 201) {
              const localSubmittedPolls = JSON.parse(localStorage.getItem('submitted_polls')) || []
              localSubmittedPolls.push(this.trainingCourseUuid)
              localStorage.setItem('submitted_polls', JSON.stringify(localSubmittedPolls))

              this.$router.replace('/poll/thanks')
            }
          }).finally(() => { this.loading = false })
        }
      })
    },
  },
}
</script>

<style scoped lang="scss">
.brand-logo img {
  width: 160px;
  display: block;
  margin: 2rem auto;
}

.poll-table {
  margin-top: 1rem;
  margin-bottom: 2rem;
  tr > td:first-child {
    width: 50%;
  }

  tr > th:first-child {
    text-align: left;
  }
  tr > th {
    text-align: center;
  }

  .custom-control.custom-radio {
    padding: 0;
    margin: 0 auto;
    width: 18px;
  }
}

.custom-control.custom-radio {
  margin: 0.5rem 0 0.5rem 0.8em
}
</style>
